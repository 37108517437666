.ContainerTVUnazad {
    display: -webkit-flex;
    width: 80%;
    margin:2% auto 0 auto;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 70%;
}

.ContainerTVUnazad .TVUnazadKat{
    width: 50%;
    /* margin:auto; */
}

.ContainerTVUnazad .TVUnazadKat div {
    margin-left:auto;
    margin-right: auto;
}