.ContainerLogout{
    position: absolute;
    right:50px;
    top:30px;
    width: 300px;
    height: 80px;
    background:white;
    border-radius: 40px;
    padding:5px;
    margin:auto 50px;
    transition: .5s;
    cursor: pointer;
}

.Logout{
    /* position: relative; */
    background: #312732;
    height: 100%;
    width: 100%;
    font-size: 30px;
    border-radius: 40px;
    display: inline-block;
   
}

.Logout p{
    position: relative;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 90%;
    color:white;
}

.ContainerLogout.active{
    transform: scale(1.1);
    background: rgb(221,16,145);
    background: linear-gradient(rgba(221,16,145,1) 0%, rgba(81,14,108,1) 100%);
}