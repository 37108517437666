.ContainerList {
  position: relative;
  width: 21%;
  margin: auto 1%;
  height: 27%;
  background: white;
  border-radius: 40px;
  padding: 5px;
  transition: 0.5s;
  cursor: pointer;
}

.List {
  position: relative;
  background: #312732;
  height: 100%;
  width: 100%;
  font-size: 30px;
  border-radius: 40px;
  display: inline-block;
  overflow: hidden;
}

.List .withoutIcon {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  color: white;
}

.List div.withIcon {
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  position: relative !important;
  width: 90%;
  text-shadow: 3px 3px 3px black;
  font-weight: bold;
}

.List img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.8;
}

.ListTime{
  position: absolute;
  font-size: 13px;
  bottom: 10%;
  left:50%;
  transform: translateX(-50%);
  margin-right: -50%;
}

.List .favorit {
  fill:white;
  position: absolute;
  right:30px;
  bottom:5px;
  transition:.5s;
}

.List .favorit:hover{
  transform:scale(1.5);
}

.List .favorit.active {
  fill:red;
}

.ContainerList.active img {
  filter: brightness(0.4);
}

.ContainerList.active {
  transform: scale(1.1);
  background: rgb(221, 16, 145);
  background: linear-gradient(
    rgba(221, 16, 145, 1) 0%,
    rgba(81, 14, 108, 1) 100%
  );
  overflow: hidden;
}
