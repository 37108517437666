.ContainerPrikaz {
    display: -webkit-flex;
    width:90%;
    margin:2% auto 0 auto;
    flex-wrap: wrap;
    height: 70%;
    justify-content: space-around;
    text-align: left;
}

.ContainerPrikaz .PrikazSlike{
    position: relative;
    height: 100%;
    width: 25%;
    overflow: hidden;
}

.ContainerPrikaz .PrikazSlike img{
    position: absolute;
    height: 100%;
    left:50%;
    transform: translateX(-50%);
}

.ContainerPrikaz .PrikazInformacije{
    font-size: 1.3em;
    width: 30%;
}

.ContainerPrikaz .PrikazInformacije p{
    margin-bottom: 5%;
}

.ContainerPrikaz .PrikazInformacije b{
    font-size:  1.3em;
    margin-right: 2px;
}

.ContainerPrikaz .Kontrole {
    width: 30%;
    height: 100%;
}

.SerijeLista {
    display: -webkit-flex;
    margin:2% auto 0 auto;
    flex-wrap: wrap;
}

.SerijeLista div{
    width: 50%;
}