.Login {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    display:-webkit-flex;
    align-items: center;
}

.LoginIn {
    margin: auto;
    margin-top:150px;
    width: 90%;
}

.Login img{
    width:13%;
    display: block;
    margin:0 auto 50px auto;
}

.Login p {
    font-size: 30px;
    margin:0 30%;
}

.Login .errorMessage{
    color: red;
    font-size: 30px;
    height: 40px;
    padding:20px 0;
}