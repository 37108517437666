.ContainerPlay{
    position: relative;
    /* width: 100%; */
    height: 80px;
    background:white;
    border-radius: 40px;
    padding:5px;
    /* min-width: 500px; */
    transition: .5s;
    cursor: pointer;
    text-align: center;
}

.ContainerPlay .Play{
    /* position: relative; */
    background: #312732;
    height: 100%;
    width: 100%;
    font-size: 40px;
    border-radius: 40px;
    display: inline-block;
   
}

.ContainerPlay .Play p{
    margin:auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 90%;
    color:white;
}

.ContainerPlay.active{
    transform: scale(1.1);
    background: rgb(221,16,145);
    background: linear-gradient(rgba(221,16,145,1) 0%, rgba(81,14,108,1) 100%);
}