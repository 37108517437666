.ContainerButton{
    background: white;
    box-sizing: border-box;
    width: 10%;
    margin:40px auto 20px auto;
    padding: 2px;
    border-radius: 40px;
    background:black;
}

.ContainerButton.active{
    background: linear-gradient(rgba(221,16,145,1) 0%, rgba(81,14,108,1) 100%)!important;
}

.Button{
    position: relative;
    font-size: 20px;
    background:black;
    padding:10%;
    color:white;
    width: 100%;
    border-radius: 40px;
    border: none;
    outline:none;
    cursor: pointer;
}

