.PageLogo {
  display: block;
  width: 80px;
  padding: 20px;
}

.PageLayout {
  height: 100%;
  width: 100%;
}

.PageLayout h1 {
  position: absolute;
  margin: 0 ;
  top: 30px;
  width: 80%;
  left: 10%;
  height: 10%;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: normal;
  overflow: hidden;
}
