.Input {
    display: block;
    text-align: center;
    border-radius: 40px;
    font-size: 20px;
    padding:10px;
    margin:35px auto;
    border:none;
    outline:none;
    width: 18%;
    border:5px solid transparent;
}

.Input.active {
    border:5px solid rgb(221,16,145) ;
}