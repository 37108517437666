.ContainerOption{
    position: relative;
    width: 20%;
    height: 40%;
    background:white;
    border-radius: 40px;
    padding:5px;
    margin:auto 50px;
    min-width: 220px;
    transition: .5s;
    cursor: pointer;
}

.Option{
    background: #312732;
    height: 100%;
    width: 100%;
    font-size: 40px;
    border-radius: 40px;
    display: inline-block;
    text-transform: uppercase;
   
}

.Option p{
    margin:auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color:white;
    text-decoration: none;
}


.ContainerOption.active{
    transform: scale(1.2);
    background: rgb(221,16,145);
    background: linear-gradient(rgba(221,16,145,1) 0%, rgba(81,14,108,1) 100%);
}