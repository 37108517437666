.Loader {
  position: relative;
  border: 24px solid white; /* Light grey */
  border-top: 24px solid rgb(221,16,145) ; /* Blue */
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
  margin:auto;
}

@keyframes spin {
  0% { transform: rotate(0deg) ;
  -webkit-transform: rotate(0deg); }
  100% { transform: rotate(360deg); 
  -webkit-transform: rotate(360deg);}
}