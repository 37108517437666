.player_full_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.Test{
  position: fixed;
  height: 100px;
  width: 100px;
  font-size:50px;
}

.video-dimensions{
  width: 100%!important;
}

#video_holder {
  width: 100%;
  height: 100vh;
}

.stream_title_holder {
  width: 100%;
  text-align: left;
  font-size: 62px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 30px;
}



#video_holder video {
  width:100%!important;
  height: 100vh;
  background: #000;
}

.controls_holder {
  position: fixed;
  padding: 100px 40px 60px 40px;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  width: 100%;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.9)
  );
  transition: 0.3s opacity linear;
  opacity: 0;
}

.controls_holder.show {
  opacity: 1;
}

.time_progress_holder {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
}

.time {
  flex-basis: 10%;
  font-size: 36px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
}

.time.time_start {
  text-align: left;
}

.time.time_end {
  text-align: right;
}

.progress_holder {
  flex-basis: 80%;
  height: 5px;
  background: rgba(150, 150, 150, 0.7);
  position: relative;
  transition: 0.2s all linear;
}

.progress_holder.active {
  height: 10px;
}

.progress {
  background: rgb(221,16,145) ;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.progress_holder.active .progress::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  background: rgb(221,16,145) ;
  border: 6px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: -13px;
  top: -9px;
  box-sizing: content-box;
}

.main_controls {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.control {
  display: inline-block;
  margin: 0 60px;
}

.control svg {
  font-size: 64px;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.2s all linear;
}

.control.active svg {
  color: rgb(221,16,145) ;
  transform: scale(1.3);
}

.loader_player {
  position: fixed;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  margin:auto;
  background:rgba(0, 0, 0, 0.9);
}

.loader_player .Loader{
  top:30%;
}