.ContainerChoice{
    position: relative;
    width: 35%;
    height: 80%;
    background:white;
    border-radius: 40px;
    padding:5px;
    margin:auto 50px;
    min-width: 500px;
    transition: .5s;
    cursor: pointer;
}

.Choice{
    /* position: relative; */
    background: #312732;
    height: 100%;
    width: 100%;
    font-size: 40px;
    border-radius: 40px;
    display: inline-block;
   
}

.Choice p{
    /* margin:auto; */
    position: relative;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 90%;
    color:white;
}

.ContainerChoice.active{
    transform: scale(1.1);
    background: rgb(221,16,145);
    background: linear-gradient(rgba(221,16,145,1) 0%, rgba(81,14,108,1) 100%);
}