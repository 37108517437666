.ContainerSezone{
    position: relative;
    height: 80px;
    background:white;
    border-radius: 40px;
    padding:5px;
    transition: .5s;
    cursor: pointer;
    text-align: center;
    width: 85%!important;
    margin:15px 0;
    overflow: hidden;
}

.ContainerSezone .Sezone{
    background: #312732;
    height: 100%;
    width: 100%;
    font-size: 20px;
    border-radius: 40px;
    display: inline-block;
   
}

.ContainerSezone .Sezone p{
    margin:auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 90%;
    color:white;
}

.ContainerSezone.active{
    transform: scale(1.1);
    background: rgb(221,16,145);
    background: linear-gradient(rgba(221,16,145,1) 0%, rgba(81,14,108,1) 100%);
}

.ContainerSezone.epizoda .Sezone{
    font-size: 15px!important;
}

.ContainerSezone.epizoda .Sezone p{
    width: 80%;
}