.Logo{
  display: block;
  width: 80px;
  margin: 20px;
}

.Nalog h1 {
  position: absolute;
  margin: 0;
  top: 30px;
  width: 100%;
  font-size: 60px;
  text-transform: uppercase;
}

.OptionsNalog {
  margin-top: 3%;
  display: -webkit-flex;
  width: 100%;
  justify-content: space-around;
  height: 80%;
  margin:auto;
}

.OptionsNalog .logout {
  position: absolute;
  width: 100px;
  height:20px;
  top:0;
  right:50px;
}