.Home {
  height: 100%;
}

.Home img {
  display: block;
  width: 80px;
  padding: 20px;
}

.OptionsHome {
  display: -webkit-flex;
  width: 100%;
  height: 70%;
  justify-content: space-around;
}
